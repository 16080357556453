<template>
  
    <div class="h-full w-full bg-body flex flex-col justify-center items-center">

        <span class="text-white text-3xl mb-20">¿ A dónde quieres ir ?</span>

        <div class="h-auto flex flex-row justify-center items-center">

            <div class="h-32 w-48 rounded-lg shadow bg-module cursor-pointer flex flex-col justify-center items-center mr-10 hover:bg-box" @click="goTo('Home')">
                <span class="text-white text-xl">VIDEOWALL</span>
            </div>

            <div class="h-32 w-48 rounded-lg shadow bg-module cursor-pointer flex flex-col justify-center items-center hover:bg-box" @click="goTo('backoffice')">
                <span class="text-white text-xl">BACKOFFICE</span>
            </div>

        </div>

    </div>

</template>

<script>
import { state } from '@/store';

export default {
    computed:{
        user(){
            return state.user
        }
    },
    methods:{
        goTo(name){
            this.$router.push( {name:name} )
        }
    }
}
</script>

<style>

</style>